import React, { useEffect } from "react"
import ReactHtmlParser from "react-html-parser"
import Layout from "../components/layout"
import { Parallax } from "react-parallax"
import enviroImage from "../../static/img/press.jpg"
const contentful = require("contentful")
const client = contentful.createClient({
  space: "qvakacqlqafz",
  accessToken: "5tDByYQwl3Ld-uJmqj4BJvBvMDx2N5f9gu0PRXJbwEM",
})
const CaseStudiesPage = ({ data }) => {
  const [press, setPress] = React.useState([])
  useEffect(async () => {
    const response = await client.getEntries({
      content_type: "press",
      order: "fields.date",
    })
    setPress(response.items)
  }, [])
  console.log("press", press)
  return (
    <Layout page="press">
      <Parallax
        bgImage={enviroImage}
        className="text-center parallax-secondary"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col" />
            <div className="col-md-7">
              <h1 className="text-white wow fadeInDown">in the PRESS</h1>
              <h3 className="text-white wow fadeInUp subtitle">
                Historical press about the founder Charlie Wilson
              </h3>
            </div>
            <div className="col">in the PRESS</div>
          </div>
        </div>
      </Parallax>
      <div className="container-fluid pb-4">
        <div className="container mb-4">
          <div className="row">
            <div className="col pt-4">
              {/* <h1 className="h1 text-primary mt-4 text-center wow fadeInUp">
                Environmental
              </h1> */}
            </div>
          </div>
          {press.map((ed, i) => (
            <div className="row" key={i}>
              <div className="col-12">
                <div className="row mt-4 mb-4 bg-white border rounded">
                  <div className="col-sm-4 col-md-3 col-lg-3 text-center wow fadeInLeft pt-4 pb-4 ">
                    <img
                      src={ed.fields.image.fields.file.url}
                      className="img-thumbnail"
                      style={{
                        border: "none",
                        borderRadius: "none",
                        boxShadow: "none",
                      }}
                    />
                  </div>
                  <div className="col-sm-8 col-md-9 col-lg-9 wow fadeInRight pt-4 pb-4">
                    <h2 className="h3 text-secondary mt-0 mb-0">
                      {ed.fields.title}
                    </h2>
                    <p className="mt-4">
                      {ReactHtmlParser(
                        ed.fields.description.replaceAll("\n", "<br/>")
                      )}
                    </p>
                    {ed.fields.link && (
                      <p className="mt-4">
                        <a href={ed.fields.link} target="_blank">
                          Learn More
                        </a>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default CaseStudiesPage
